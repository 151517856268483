import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import Pagination from '../components/utils/pagination'
import SingleLayout from '../components/layouts/SingleLayout_10'
import SingleCard from '../components/cards/card'
import Seo from "../components/seo"
const _ = require("lodash");

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges } = data.allMarkdownRemark

  const title = `Explore posts for: ${category}`;
  const description = `Explore curated posts in category: ${category}.`

  return (
    <SingleLayout>
      <Seo
        title={title}
        description={description}
      />
      <div className="w-lg-60 mx-lg-auto">
        <div className="mb-2">
          <h1 className="h1 mb-4 mt-4">
            Explore Posts for <span className="text-primary text-highlight-warning">
              {_.startCase(_.toLower(category))}
              </span>
          </h1>
        </div>
      </div>

      <div className="w-lg-80 mx-lg-auto">
        <div className="row mb-3">
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <SingleCard key={slug} title={title} href={slug} 
                desc={node.excerpt} img={node.frontmatter.featuredImage.childImageSharp.fluid} />
            )
          })}
        </div>
        <Pagination 
            currentPage={pageContext.currentPage} 
            numPages={pageContext.numPages} 
            baseSlug={pageContext.categorySlug} />
        {/* <Link to="/tags">All tags</Link> */}
      </div>
    </SingleLayout>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
  query($category: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 80)
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`